﻿@use 'themes';

@mixin nc-webkit-autofill-theme($theme) {
    input, select, textarea {
        &:-webkit-autofill {
            box-shadow: 0 0 0 50px map-get($theme, background) inset;
            -webkit-text-fill-color: map-get($theme, on-background);
        }
    }
}

input, select, textarea {
    &:-webkit-autofill {
        filter: grayscale(21%) brightness(88%) contrast(161%) invert(10%) sepia(40%) saturate(206%);
    }
}

@include nc-webkit-autofill-theme(themes.$theme-light);

.theme-dark {
    @include nc-webkit-autofill-theme(themes.$theme-dark);
}
