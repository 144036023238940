﻿@use 'themes';

@mixin nc-separator-theme($theme) {
    .nc-horizontal-separator {
        &:before, &:after {
            background-color: rgba(map-get($theme, on-background), 0.33);
        }
    }
}

.nc-horizontal-separator {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: stretch;
    margin-bottom: 8px;

    &:before, &:after {
        content: "";
        display: block;
        flex: 1 0 auto;
        height: 1px;
    }

    &:not(.nc-horizontal-separator--empty) {
        &:before {
            margin-right: 8px;
        }

        &:after {
            margin-left: 8px;
        }
    }
}

@include nc-separator-theme(themes.$theme-light);

.theme-dark {
    @include nc-separator-theme(themes.$theme-dark);
}
