﻿body, html {
    margin: 0;
    height: 100%;
}

.navbar-header {
    position: relative;
    top: -4px;
}

.navbar-brand > .icon-banner {
    position: relative;
    top: -2px;
    display: inline;
}

.icon {
    position: relative;
    top: -10px;
}

iframe {
    display: none;
    width: 0;
    height: 0;
}

.page-consent {
    .client-logo {
        float: left;

        img {
            width: 80px;
            height: 80px;
        }
    }

    .consent-buttons {
        margin-top: 25px;
    }

    .consent-form {
        .consent-scopecheck {
            display: inline-block;
            margin-right: 5px;
        }

        .consent-description {
            margin-left: 25px;

            label {
                font-weight: normal;
            }
        }

        .consent-remember {
            padding-left: 16px;
        }
    }
}

.grants {
    .page-header {
        margin-bottom: 10px;
    }

    .grant {
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid lightgray;

        img {
            width: 100px;
            height: 100px;
        }

        .clientname {
            font-size: 140%;
            font-weight: bold;
        }

        .granttype, .created, .expires {
            font-size: 120%;
            font-weight: bold;
        }

        li {
            list-style-type: none;
            display: inline;

            &:after {
                content: ', ';
            }

            &:last-child:after {
                content: '';
            }
        }
    }
}


.code {
    background-color: rgba(128,128,128, 0.5);
    font-family: monospace;
}