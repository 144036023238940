﻿@use 'themes';

@mixin selectize-theme($theme) {
    .selectize-field {
        label {
            color: map-get($theme, label);
        }

        .selectize-control {
            .selectize-input, .selectize-dropdown {
                color: map-get($theme, on-background);
                background-color: map-get($theme, background);
                border-color: map-get($theme, border);
            }

            .selectize-input {
                border-radius: 8px;

                input {
                    color: map-get($theme, on-background);
                }
            }

            &:hover {
                .selectize-input,
                .selectize-input ~ .selectize-dropdown {
                    border-color: map-get($theme, on-background);
                }
            }

            .selectize-input.focus,
            .selectize-input.focus ~ .selectize-dropdown {
                border-color: map-get($theme, border);
            }
        }

        .selectize-dropdown {
            .active {
                background-color: rgba(map-get($theme, primary), 0.16);
            }

            .selected {
                color: map-get($theme, on-primary);
                background-color: map-get($theme, primary);
            }

            [data-selectable] .highlight {
                background-color: rgba(map-get($theme, primary), 0.6);
            }
        }
    }
}

.selectize-field {
    label {
        font-size: 0.75rem;
        padding-left: 17px; // Align with material label
    }

    .selectize-control {
        .selectize-input {
            width: 100%;
            height: 80px;
            background: none;
            font-size: 1rem;
            line-height: 1.75rem;
            padding: 25px 16px 14px;

            input {
                font-size: inherit;
            }
        }

        .selectize-input, .selectize-dropdown {
            box-shadow: none;
            transition: border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
        }

        .selectize-input.focus,
        .selectize-input.focus ~ .selectize-dropdown {
            border-width: 2px;
        }

        .selectize-input.focus {
            padding: 24px 15px 13px;
            border-bottom-width: 1px;
        }

        .selectize-dropdown .option, .selectize-dropdown .optgroup-header {
            padding: 25px 16px;
            font-size: 1rem;
        }

        .selectize-dropdown .active {
            color: unset;
        }
    }
}

@include selectize-theme(themes.$theme-light);

.theme-dark {
    @include selectize-theme(themes.$theme-dark);
}
