﻿.form-vertical-container {
    display: flex;
    flex-direction: column;

    .small-margin-right {
        margin-right: 200px;
    }

    .form-actions, .form-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row-reverse;
    }

    .form-row {
        display: flex;
        flex-direction: column;
    }

    @media screen and (min-width:600px) {
        .form-row {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            flex-wrap: nowrap;

            .form-control + .form-control {
                margin-left: 12px;
            }
        }
    }

    > .form-control {
        margin-bottom: 25px;

        &.selectize-field {
            margin-bottom: 45px;
        }

        &.big-bottom-margin {
            margin-bottom: 66px;
        }
    }

    .mdc-text-field-helper-line {
        margin-top: -25px;
        margin-bottom: 25px;
    }

    .form-actions {
        gap: 24px;
        a {
            text-decoration: none;
        }

        button {
            border: none;
        }

        & + .form-actions {
            margin-top: 24px;
        }

        div, a, button {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 170px;
            text-align: center;
            height: 50px
        }
    }
}

.font-large {
    font-size: 48px;
}

.font-medium {
    font-size: 21px;
}

.flex-center {
    justify-content: center;
}

.flex-space-between {
    justify-content: space-between;
}

.center-text {
    display: flex;
    align-items: center;
}

.form-horizontal-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 25px;

    &.align-middle {
        align-items: center;
    }

    > .form-control--no-padding,
    > .form-control {
        border: none;
        flex-basis: 0;
        flex-grow: 1;
        border-radius: 36px;
        max-width: 300px;
        text-align: center;
        height: 80px;

        .mdc-checkbox {
            vertical-align: middle;
        }
    }

    > a.form-control--no-padding,
    > a.form-control,
    > label.form-control--no-padding,
    > label.form-control {
        text-decoration: none;
        line-height: 80px;
        white-space: nowrap;
    }

    > .form-control + .form-control {
        margin-left: 25px;
    }
    // Align text field with select vertically
    > .selectize-field {
        margin-top: -19px;
    }
}

.selectize-field {
    height: 80px;
}

.font-small-bold {
    font-size: 19px;
    font-weight: bold;
}

.aligned-to-checkbox {
    text-align: left !important;
    margin-left: 48px !important;
}

a.nomadis-link {
    text-decoration: none;
    color: #f26227;
    margin-left: 15px;
}

.recovery-code {
    margin-bottom: 4px;

    > span {
        user-select: all;
        text-align: center;
        padding: 2px 6px;
        border-radius: 4px;
    }
}

.warning-panel {
    background-color: var(--mdc-theme-secondary);
    border-radius: 8px;
    padding: 8px;
    opacity: 0.85;
}

.caption {
    color:lightgray;
}