@use "../../styles/themes";
@use "@material/theme" with (
    $primary: #2b2b2b,
    $on-primary: #FFF,
    $secondary: #f26227,
    $on-secondary: #000,
    $background: #fff,
    $surface: #f5f7f9,
    $on-surface: #000,
    $error: #f52525,
    $on-error: #fff,
);
@use "@material/typography" with (
    $font-family: unquote("Cabin, Arial, Helvetica, sans-serif"),
    $styles-subtitle1: (font-family: unquote("Mulish, Arial, Helvetica, sans-serif")),
    $styles-button: (font-size: 1.2rem, font-weight: bold, text-transform: none)
);
@use "@material/button";
@use "@material/card";
@use "@material/checkbox";
@use "@material/floating-label";
@use "@material/form-field";
@use "@material/line-ripple";
@use "@material/list";
@use "@material/menu";
@use "@material/menu-surface";
@use "@material/notched-outline";
@use "@material/select" as mdc-select with (
    $height: 80px,
    $minimum-height: 80px,
    $maximum-height: 80px
);
@use "@material/select/select";
@use "@material/slider/slider";
@use "@material/snackbar";
@use "@material/switch" with (
    $track-width: 80px,
    $track-height: 42px,
    $thumb-diameter: 28px,
    $ripple-size: 40px
);
@use "@material/tab";
@use "@material/tab-bar";
@use "@material/tab-indicator";
@use "@material/tab-scroller";
@use "@material/textfield" with (
    $height: 80px,
    $minimum-height: 80px,
    $maximum-height: 80px
);
@use "@material/textfield/helper-text";
@use '@material/notched-outline/mixins' as notched-outline-mixins;

// include Material core styles
@include button.core-styles();
@include card.core-styles();
@include checkbox.core-styles();
@include floating-label.core-styles();
@include form-field.core-styles();
@include line-ripple.core-styles();
@include list.core-styles();
@include menu.core-styles();
@include menu-surface.core-styles();
@include notched-outline.core-styles();
@include select.core-styles();
@include slider.core-styles();
@include snackbar.core-styles();
@include switch.core-styles();
@include tab.core-styles();
@include tab-bar.core-styles();
@include tab-indicator.core-styles();
@include tab-scroller.core-styles();
@include textfield.core-styles();
@include theme.core-styles();
@include typography.core-styles();

// Import Selectize stylesheet
@import 'selectize/dist/css/selectize.default';
@import '../../styles/selectize-tweak';

// Import our style utilities
@import '../../styles/flex';
@import '../../styles/form';
@import '../../styles/material';
@import '../../styles/separator';
@import '../../styles/text';
@import '../../styles/webkit-autofill';
@import '../../styles/alerts';

// Import Identity style
@import '../../styles/site';

// Use border-box sizing
html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }

// Apply font to body
body {
    font-family: 'Cabin','Helvetica','Arial', sans-serif !important;
}

.mdc-list-item--selected {
    font-weight: bold;
}

#language-dropdown {
    z-index: 1;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 230px;

    .mdc-list {
        min-width: max-content;
    }

    .mdc-list-item + .mdc-list-item {
        border-top: 1px solid var(--mdc-theme-on-surface);
    }

    .material-icons {
        z-index: 1;
        margin-left: -10px;
    }

    .mdc-select__anchor {
        align-items: center;
    }
}

#selected-organization-chip {
    position: absolute;
    top: 16px;
    left: 52%;
    background-color: lightgray;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    padding: 8px 30px;

    img {
        width: 40px;
        height: 40px;
    }
}

// Dark theme style
.theme-dark {
    --mdc-theme-primary: #{map-get(themes.$theme-dark, primary)};
    --mdc-theme-on-primary: #{map-get(themes.$theme-dark, on-primary)};
    --mdc-theme-secondary: #{map-get(themes.$theme-dark, secondary)};
    --mdc-theme-on-secondary: #{map-get(themes.$theme-dark, on-secondary)};
    --mdc-theme-background: #{map-get(themes.$theme-dark, background)};
    --mdc-theme-surface: #{map-get(themes.$theme-dark, surface)};
    --mdc-theme-on-surface: #{map-get(themes.$theme-dark, on-surface)};

    --mdc-theme-text-primary-on-background: var(--mdc-theme-text-primary-on-dark);
    --mdc-theme-text-secondary-on-background: var(--mdc-theme-text-secondary-on-dark);
    --mdc-theme-text-hint-on-background: var(--mdc-theme-text-hint-on-dark);
    --mdc-theme-text-disabled-on-background: var(--mdc-theme-text-disabled-on-dark);
    --mdc-theme-text-icon-on-background: var(--mdc-theme-text-icon-on-dark);

    color: var(--mdc-theme-on-surface);
    background-color: var(--mdc-theme-background);

    // Some components doesn't use css variable for colors, so they need to be overridden here
    .mdc-select {
        @include mdc-select.label-color(map-get(themes.$theme-dark, on-background));
        @include mdc-select.ink-color(map-get(themes.$theme-dark, on-background));
        @include mdc-select.outline-color((
                default: rgba(map-get(themes.$theme-dark, border), 0.38),
                hover: rgba(map-get(themes.$theme-dark, on-background), 0.87)
        ));
    }
    .mdc-text-field {
        @include textfield.label-color(map-get(themes.$theme-dark, on-background));
        @include textfield.ink-color(map-get(themes.$theme-dark, on-background));
        @include textfield.outline-color(rgba(map-get(themes.$theme-dark, border), 0.38));
        @include textfield.hover-outline-color(rgba(map-get(themes.$theme-dark, on-background), 0.87));
        @include helper-text.helper-text-color(map-get(themes.$theme-dark, on-background));
    }
    
    .mdc-text-field--invalid {
        @include textfield.invalid_();
    }

    .mdc-text-field--disabled {
        @include notched-outline-mixins.color(rgba(map-get(themes.$theme-dark, on-surface), 0.06));
        @include textfield.ink-color_(rgba(map-get(themes.$theme-dark, on-surface), 0.38));
        @include textfield.label-ink-color_(rgba(map-get(themes.$theme-dark, on-surface), 0.38));
    }

    .mdc-menu .mdc-list {
        @include list.item-primary-text-ink-color(map-get(themes.$theme-dark, on-surface));
    }
    .mdc-checkbox {
        @include checkbox.container-colors(
                        map-get(themes.$theme-dark, background),
                        rgba(map-get(themes.$theme-dark, on-background), 0.50),
                        map-get(themes.$theme-dark, on-background),
                        rgba(map-get(themes.$theme-dark, on-background), 0.50),
                        false);
    }
    .mdc-tab {
        @include tab.text-label-color(map-get(themes.$theme-dark, on-background));
    }
}

// Use secondary color for active tabs instead of primary
.mdc-tab.mdc-tab--active {
    .mdc-tab__content .mdc-tab__text-label,
    .mdc-tab-indicator.mdc-tab-indicator--active .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
        color: var(--mdc-theme-secondary, #f26227);
    }

    .mdc-tab-indicator.mdc-tab-indicator--active .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
        border-top-color: var(--mdc-theme-secondary, #f26227);
    }
}

// Fix Material switch
.mdc-switch__thumb-underlay { left: 0; }
.mdc-switch--checked .mdc-switch__thumb-underlay { transform: translateX(40px); }

// Add rounded border to all buttons
.mdc-button {
    border-radius: 36px !important;
}

// Outlined button have a background color
.mdc-button--outlined {
    background-color: button.$outline-color !important;
}
