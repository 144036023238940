﻿.mdc-notched-outline.mdc-notched-outline--shaped {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
        width: 28px;
    }
}

.mdc-notched-outline {
    .mdc-notched-outline__leading {
        border-radius: 8px 0 0 8px !important;
    }

    .mdc-notched-outline__trailing {
        border-radius: 0 8px 8px 0 !important;
    }
}

.mdc-snackbar .mdc-snackbar__label {
    font-size: 18px;
}
